/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Museo Sans 500';
  src: url('assets/fonts/MuseoSans-500.woff2') format('woff2'),
      url('assets/fonts/MuseoSans-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans 900';
  src: url('assets/fonts/MuseoSans-900.woff2') format('woff2'),
      url('assets/fonts/MuseoSans-900.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Museo Sans 500', Roboto, "Helvetica Neue", sans-serif; }
*{
  font-family: 'Museo Sans 500', Roboto, "Helvetica Neue", sans-serif;
}
.btn-primary {
  background-color: #00B398 !important;
}

.font-museo-500{
  font-family: 'Museo Sans 500' !important;
}
.font-museo-900{
  font-family: 'Museo Sans 900' !important;
}
:root {
  --header-height: 2rem;
  --nav-width: 58px;
  --first-color: #FC4C02;
  --first-color-light: #ffffff;
  --white-color: #9d9d9d;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100 ;
}


.header-app {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: white;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle-app {
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img-app {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img-app img {
  width: 40px;
}

.l-navbar-app {
  position: fixed;
  top: -20px;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: #ffffff;
  padding: 0.5rem 0rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}


.nav-app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo-app,
.nav_link-app {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo-app {
  margin-bottom: 2rem;
}

.nav_logo-icon-app {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name-app {
  color: var(--white-color);
  font-weight: 700;
}

.nav_link-app {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav_link-app:hover {
  color: var(--white-color);
}

.nav_icon-app {
  font-size: 1.25rem;
}

.show-app {
  left: 0;
}

.profile-info{
  visibility: hidden;
}

.show-app .profile-info{
  visibility: visible !important;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active-app {
  color: var(--white-color);
}

.active-app::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  body {
    /*margin: calc(var(--header-height) + 1rem) 0 0 0;*/
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .header-app {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header_img-app {
    width: 40px;
    height: 40px;
  }

  .header_img-app img {
    width: 45px;
  }

  .l-navbar-app {
    left: 0;
    padding: 1rem 0rem 0 0;
  }

  .show-app {
    width: calc(var(--nav-width) + 180px);
  }

  .body-pd-app {
    padding-left: calc(var(--nav-width) + 188px);
  }

}

@media screen and (min-width: 360px) {
  body {
    /*margin: calc(var(--header-height) + 1rem) 0 0 0;*/
    padding-left: calc(var(--nav-width) + 0.1rem);
  }

  .header-app {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 1rem);
  }

  .header_img-app {
    width: 40px;
    height: 40px;
  }

  .header_img-app img {
    width: 45px;
  }

  .l-navbar-app {
    left: 0;
    padding: 1rem 0rem 0 0;
  }

  .show-app {
    width: calc(var(--nav-width) + 100px);
  }

  .body-pd-app {
    padding-left: calc(var(--nav-width) + 108px);
  }

}
